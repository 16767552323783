var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("filter"),
      _c(
        "vs-table",
        {
          class: _vm.tableStyle,
          attrs: {
            sst: true,
            pagination: "",
            "max-items": _vm.maxItems,
            data: _vm.model.data,
            total: _vm.totalItems,
            "no-data-text": _vm.noDataText,
            "current-sort-type": "asc",
            max: _vm.paginationMaxItems,
          },
          on: {
            "change-page": _vm.handleChangePage,
            sort: _vm.handleSort,
            selected: function ($event) {
              return _vm.$emit("selected", $event)
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function ({ data }) {
                  return [
                    _vm._t(
                      "gridData",
                      function () {
                        return _vm._l(data, function (tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr, attrs: { data: tr } },
                            [
                              _vm._l(_vm.columns, function (column, key) {
                                return _c(
                                  "vs-td",
                                  {
                                    key: key,
                                    attrs: { data: data[indextr].id },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class: _vm.getColumnCss(
                                          data[indextr],
                                          key
                                        ),
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getColumnData(
                                                data[indextr],
                                                key
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              !_vm.hide_actions
                                ? _c(
                                    "vs-td",
                                    [
                                      !_vm.hide_edit
                                        ? _c("feather-icon", {
                                            directives: [
                                              {
                                                name: "permission",
                                                rawName: "v-permission",
                                                value: `${_vm.route_name}.edit`,
                                                expression:
                                                  "`${route_name}.edit`",
                                              },
                                            ],
                                            attrs: {
                                              icon: "EditIcon",
                                              svgClasses:
                                                "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.edit(
                                                  data[indextr].id
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      !_vm.hide_show
                                        ? _c("feather-icon", {
                                            attrs: {
                                              icon: "EyeIcon",
                                              svgClasses:
                                                "h-5 w-5 mb-1 mr-3 stroke-current text-success",
                                            },
                                          })
                                        : _vm._e(),
                                      !_vm.hide_delete
                                        ? _c("feather-icon", {
                                            directives: [
                                              {
                                                name: "permission",
                                                rawName: "v-permission",
                                                value: `${_vm.route_name}.delete`,
                                                expression:
                                                  "`${route_name}.delete`",
                                              },
                                            ],
                                            attrs: {
                                              icon: "Trash2Icon",
                                              svgClasses:
                                                "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.destroy(
                                                  data[indextr]
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          )
                        })
                      },
                      {
                        data: data,
                        destroy: _vm.destroy,
                        edit: _vm.edit,
                        fetchGridData: _vm.fetchGridData,
                      }
                    ),
                  ]
                },
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.itemSelected,
            callback: function ($$v) {
              _vm.itemSelected = $$v
            },
            expression: "itemSelected",
          },
        },
        [
          _c("template", { slot: "header" }, [
            _c(
              "div",
              { staticClass: "vs-row w-full pb-2 gap-2 flex items-end" },
              [
                _c(
                  "div",
                  { staticClass: "flex-grow" },
                  [_vm._t("filterZone")],
                  2
                ),
                !_vm.hide_searchbar
                  ? _c(
                      "div",
                      { staticClass: "flex w-full sm:w-auto gap-1" },
                      [
                        _c("vs-input", {
                          staticClass: "searchbar",
                          attrs: { icon: "search" },
                          on: {
                            input: function ($event) {
                              return _vm.handleSearch(_vm.searchInput)
                            },
                          },
                          model: {
                            value: _vm.searchInput,
                            callback: function ($$v) {
                              _vm.searchInput = $$v
                            },
                            expression: "searchInput",
                          },
                        }),
                        _vm.showExport
                          ? _c("button-alternates", {
                              attrs: {
                                label: _vm.$t("exportar"),
                                buttonClass: "px-4",
                                alternatives: [
                                  {
                                    icon: "picture_as_pdf",
                                    label: "PDF",
                                    color: "red",
                                    click: () => {
                                      _vm.exportGrid("pdf")
                                    },
                                  },
                                  {
                                    icon: "border_all",
                                    label: "xls",
                                    color: "success",
                                    click: () => {
                                      _vm.exportGrid("xls")
                                    },
                                  },
                                ],
                              },
                              on: {
                                "click-main": function ($event) {
                                  return _vm.exportGrid("pdf")
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.showTotalText
                          ? _c(
                              "div",
                              { staticClass: "w-full mt-2 text-right" },
                              [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.showTotalText) + ":"),
                                ]),
                                _vm._v(" " + _vm._s(_vm.totalItems)),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
          _vm.theadShow
            ? _c(
                "template",
                { slot: "thead" },
                [
                  _vm._t(
                    "gridThead",
                    function () {
                      return _vm._l(_vm.columns, function (column, key) {
                        return _c(
                          "vs-th",
                          {
                            key: key,
                            attrs: { "sort-key": key, "sort-status": "asc" },
                          },
                          [_vm._v(_vm._s(_vm.$t(column)))]
                        )
                      })
                    },
                    { data: _vm.columns }
                  ),
                  _vm._t("gridActions", function () {
                    return [
                      !_vm.hide_actions
                        ? _c("vs-th", { attrs: { "sort-key": "actions" } }, [
                            _vm._v(_vm._s(_vm.$t("Actions"))),
                          ])
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }